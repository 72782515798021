import dynamic from 'next/dynamic';

export default {
    ArticlePage: dynamic(() => import('./ArticlePage')),
    BasePage: dynamic(() => import('./BasePage')),
    CustomerServiceArticlePage: dynamic(
        () => import('./CustomerServiceArticlePage')
    ),
    CustomerServiceCategoryPage: dynamic(
        () => import('./CustomerServiceArticlePage')
    ),
    CustomerServicePage: dynamic(() => import('./CustomerServicePage')),
    CustomerServiceSubcategoryPage: dynamic(
        () => import('./CustomerServiceArticlePage')
    ),
    HomePage: dynamic(() => import('./HomePage')),
    LandingPage: dynamic(() => import('./LandingPage')),
    LandingTagPage: dynamic(() => import('./LandingTagPage')),
    NewsHubPage: dynamic(() => import('./NewsHubPage')),
    NewsHubLandingPage: dynamic(() => import('./NewsHubLandingPage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PodcastPage: dynamic(() => import('./PodcastPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    VideoPage: dynamic(() => import('./VideoPage')),
};
